<div class="badge badge-secondary" [tp]="description()">
  @if(icon(); as icon) {
  <ng-icon [name]="icon" class="mr-1"></ng-icon>
  }

  <span class="mr-1">{{ displayValue() | number }}</span>

  @if(!icon()) {
  <app-content-name [id]="id()"></app-content-name>
  }
</div>